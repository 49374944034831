<template>
  <section class="content">
    <div class="card card-primary">
      <!-- form start -->
      <form role="form" id="form_user" class="form-summary">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="exampleInputEmail1" class="col-6">Kode Order</label>
                <span class="col-6">{{ orderCode }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">No Resi</label>
                <span class="col-6"
                  ><h4>
                    {{ info.no_resi }}
                    <a href="#" class="btn btn-sm btn-default"
                      ><i class="fa fa-copy"></i
                    ></a></h4
                ></span>
              </div>
              <div class="form-group row">
                <label class="col-6">Service</label>
                <span class="col-6">{{ info.courier_service_code }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Tanggal Kirim</label>
                <span class="col-6">{{ info.ordered_on }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Status</label>
                <span class="col-6"
                  ><span :class="'badge badge-' + info.statusType"
                    >{{ info.statusMsg }}
                  </span>
                  <a href="#" class="ml-2 lacak" v-on:click="lacakResi">
                    Lacak kiriman</a
                  ></span
                >
              </div>
              <h3 class="mt-3">Info Paket</h3>
              <div class="form-group row">
                <label class="col-6">Isi Paket</label>
                <span class="col-6">{{ info.item_name }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Berat</label>
                <span class="col-6">{{ info.item_weight }} Kg</span>
              </div>
              <div class="form-group row" v-if="info.status_cod == 0">
                <label class="col-6">Nilai Paket</label>
                <span class="col-6"
                  ><span>{{ formatPrice(info.item_value) }}</span></span
                >
              </div>
              <div class="form-group row" v-if="info.status_cod == 1">
                <label class="col-6">Nilai COD</label>
                <span class="col-6"
                  ><span>{{ formatPrice(info.item_value) }}</span></span
                >
              </div>
              <div class="form-group row">
                <label class="col-6">Nilai Ongkir</label>
                <span class="col-6">{{ formatPrice(info.nilai_ongkir) }}</span>
              </div>
              <div class="form-group row">
                <label class="col-6">Biaya Asuransi</label>
                <span class="col-6">{{
                  info.insurance_fee == 0.0 ? "FREE" : formatPrice(info.insurance_fee)
                }}</span>
              </div>
            </div>
          </div>
          <br />
          <table class="table table-bordered">
            <tr>
              <th width="20%"></th>
              <th width="40%">Pengirim</th>
              <th width="40%">Penerima</th>
            </tr>
            <tr>
              <th>Nama</th>
              <td>{{ info.sender_name }}</td>
              <td>{{ info.receiver_name }}</td>
            </tr>
            <tr>
              <th>Alamat</th>
              <td>{{ info.sender_address }}</td>
              <td>{{ info.receiver_address }}</td>
            </tr>
            <tr>
              <th>Telpon</th>
              <td>{{ info.sender_phone }}</td>
              <td>{{ info.receiver_phone }}</td>
            </tr>
          </table>
          <hr />
        </div>
        <!-- /.box-body -->
        <div class="card-footer form-actions">
          <!--<router-link
            :to="{ path: '/order/edit/' + info.group_id }"
            class="btn btn-primary ml-2"
          >
            <i class="fa fa-undo"></i> Kembali
          </router-link>-->
          <router-link
            :to="{ path: '/payment-kurir' }"
            class="btn btn-primary ml-2"
          >
            <i class="fa fa-undo"></i> Kembali
          </router-link>
          <button
            v-if="false"
            class="btn btn-primary ml-2"
            @click="$router.go(-1)"
          >
            <i class="fa fa-undo"></i> Kembali
          </button>
        </div>
      </form>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Riwayat Kiriman</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <section data-unify="Card" class="card-body">
              <div>
                <div class="css-h81dkj" v-for="row in history" v-bind:key="row">
                  <div class="date">{{ row.time }}<br />{{ row.date }}</div>
                  <div class="line-wrapper">
                    <div class="circle"></div>
                    <div class="line"></div>
                  </div>
                  <div class="status-wrapper">
                    <div class="status">{{ row.note }}</div>
                    <div class="city">{{ row.branch }}</div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";

export default {
  name: "Orderdetail",
  data() {
    return {
      info: {
        id: "",
      },
      history: [],
      orderCode: "",
    };
  },
  components: {},
  computed: {},
  created: function () {
    var uinfo = auth.user();
    if (uinfo) {
    }
  },
  methods: {
    loadInfo: function (code) {
      authFetch("/payment/courier/detail/" + code).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.formatData(json.data);
          }
        });
      });
    },
    formatData: function (info) {
      if (info.tracking_status == 0) {
        if(info.order_type == 1){
          info.statusMsg = "Menunggu Drop";
        } else {
          info.statusMsg = "Menunggu Pickup";
        }
        info.statusType = "secondary";
      } else if (info.tracking_status == 1) {
        info.statusMsg = "Perjalanan";
        info.statusType = "info";
      } else if (info.tracking_status == 2) {
        info.statusMsg = "Terkirim";
        info.statusType = "success";
      } else if (info.tracking_status == 3) {
        info.statusMsg = "Proses Retur";
        info.statusType = "warning";
      } else if (info.tracking_status == 4) {
        info.statusMsg = "Retur Selesai";
        info.statusType = "danger";
      }
      this.info = info;
    },
    lacakResi() {
      const e = this.$refs;
      $(e.formDialog).modal("show");
    },
    loadHistory: function (code) {
      authFetch("/order/history/" + code).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            //this.formatData(json.data);
            this.history = json.data;
          }
        });
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    goBack() {
      this.$router.back();
    },
  },
  mounted() {
    this.orderCode = this.$route.params.id;
    this.loadInfo(this.orderCode);
    const e = this.$refs;
    $(e.formDialog).on("show.bs.modal", (e) => {
      if (this.history.length == 0) {
        /*this.history.push({
                time: '00.00',
                date: '7 Jul',
                note: 'Tiba di counter',
                branch: 'Jakarta'
            },
            {
                time: '00.00',
                date: '7 Jul',
                note: 'Tiba di counter',
                branch: 'Jakarta'
            })*/
        this.loadHistory(this.orderCode);
      }
    });
  },
};
</script>
<style>
.form-summary .form-group {
  margin-bottom: 0;
}
.form-summary .badge {
  font-size: 100%;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.css-14fpj81-unf-card {
  display: block;
  position: relative;
  margin: 16px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 var(--color-shadow, rgba(49, 53, 59, 0.12));
  background-color: var(--color-card, #ffffff);
  cursor: default;
}

.css-h81dkj {
  width: 100%;
  vertical-align: top;
  display: flex;
  min-height: 60px;
}
.css-h81dkj .date {
  text-align: right;
  width: 18%;
  flex-shrink: 0;
  color: var(--N200, #9fa6b0);
  font-size: 0.857143rem;
  line-height: 1.5;
}
.css-h81dkj .line-wrapper {
  flex-basis: 0px;
  margin: 0px 16px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  width: 3%;
  display: flex;
}
.css-h81dkj:first-child .circle {
  background-color: transparent;
  animation: blinker 1s linear infinite;
}
.css-h81dkj .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-bottom: auto;
  background-color: var(--N100, #dbdee2);
}
.css-h81dkj .line-wrapper .line {
  width: 1px;
  height: calc(100% - 9px);
  margin-bottom: -6px;
  background-image: linear-gradient(
    to bottom,
    var(--N100, #dbdee2) 55%,
    rgba(255, 255, 255, 0) 55%
  );
  background-position: left center;
  background-size: 1px 12px;
  background-repeat: repeat-y;
}
.css-h81dkj:first-child .status-wrapper {
  padding-bottom: 36px;
}
.css-h81dkj:first-child .status-wrapper .status {
  font-weight: 800;
  line-height: 1.4;
}
.css-h81dkj .status-wrapper .status {
  color: var(--N500, #52565e);
  line-height: 1.5;
  margin-top: -0.25rem;
}
.css-h81dkj:first-child .status-wrapper .city {
  color: var(--N200, #9fa6b0);
}

.css-h81dkj .status-wrapper .city {
  color: var(--N200, #9fa6b0);
  margin-top: 8px;
}
.css-h81dkj:first-child .circle::before {
  width: 15px;
  position: absolute;
  height: 15px;
  border-radius: 50%;
  background-color: var(--G300, #f9b79d);
  transition: all 0.5s ease-out 0s;
  animation-name: grow;
  animation-duration: 1400ms;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
  content: "";
}
.css-h81dkj:first-child .circle::after {
  border-radius: 50%;
  background-color: var(--G500, #e96936);
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  left: 2px;
  top: 2px;
}
.css-h81dkj .circle::after {
  border-radius: 50%;
  background-color: var(--N200, #9fa6b0);
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  left: 3px;
  top: 3px;
}
.css-h81dkj:last-of-type .line {
  display: none;
}
.css-h81dkj .line-wrapper .line {
  width: 1px;
  height: calc(100% - 9px);
  margin-bottom: -6px;
  background-image: linear-gradient(
    to bottom,
    var(--N100, #dbdee2) 55%,
    rgba(255, 255, 255, 0) 55%
  );
  background-position: left center;
  background-size: 1px 12px;
  background-repeat: repeat-y;
}
</style>